<template>
  <div class="courseDetail">
    <div class="top-info">
      <div class="top-content">
        <div class="title-box">
          <div @click="$router.back()">
            <svg-icon icon-class="backIcon" class-name="backIcon"></svg-icon>
          </div>
          <img
            src="../../../assets/images/learn/course-detail-title.png"
            alt=""
          />
        </div>
        <div class="course-info" v-if="taskDetail">
          <img
            :src="ConstData.courseSource[taskDetail.type].picUrl"
            class="project-icon"
            alt=""
          />
          <img :src="taskDetail?.coverImage" class="cover" alt="" />
          <div class="info-right">
            <p class="label" v-if="taskDetail.contentType">
              {{ ConstData.taskType[taskDetail.contentType].text }}
            </p>
            <p class="title">{{ taskDetail.name }}</p>
            <div
              class="taskItemTypeList"
              v-if="taskDetail.courseItemTypeList && taskDetail.type === 1"
            >
              <p
                v-for="item in taskDetail.courseItemTypeList"
                v-show="item.type != 1"
                :key="item.type"
              >
                <span>{{ ConstData.taskType[item.type].text }}：</span>
                <span>{{ item.count }}</span>
              </p>
            </div>
            <p class="time">
              课程周期：{{
                taskDetail.studyType === 1
                  ? `${taskDetail.startTime
                      ?.split(" ")[0]
                      .replace(/-/g, ".")} - ${taskDetail.endTime
                      ?.split(" ")[0]
                      .replace(/-/g, ".")}`
                  : taskDetail.studyType === 2
                  ? `有效期至${taskDetail.endTime
                      ?.split(" ")[0]
                      .replace(/-/g, ".")}`
                  : "长期有效"
              }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom-tree">
      <div class="tab">
        <p
          class="item"
          :class="{ active: tabIndex === item.id }"
          @click="jump(item.id)"
          v-for="item in tabList"
          :key="item.id"
        >
          {{ item.name }}
        </p>
      </div>
      <div class="hid-scroll-bar">
        <div class="scroll-box" id="scroll-box">
          <div v-if="tabIndex == 'anchor1'" class="introduction" id="anchor1">
            <div v-html="taskDetail?.description"></div>
          </div>
          <div v-if="tabIndex == 'anchor2'" class="catalogue" id="anchor2">
            <p class="title">课程目录</p>
            <tree :data="treeData" @clickNode="clickNode" />
          </div>
        </div>
      </div>
    </div>
    <facedialog
      v-if="verification"
      :confirmdialog="confirmdialog"
      :address="verificationAddress"
      :failAddress="verificationFailAddress"
      :type="1"
      :is_course_image_export="true"
      :pauseFrame="true"
      :businessId="businessId"
      :taskId="+taskId"
    ></facedialog>
    <offlineTrainingDialog
      v-if="dialogVisible.show"
      :offlineTrainInfo="offlineTrainInfo"
      :dialogVisible="dialogVisible"
    ></offlineTrainingDialog>
  </div>
</template>

<script>
import tree from "./../components/tree";
import { goLiveHome } from "@/utils/liveToHome.js";
import offlineTrainingDialog from "../components/offlineTrainingDialog";
export default {
  components: { tree, offlineTrainingDialog },
  data() {
    return {
      dialogVisible: {
        show: false,
      },
      confirmdialog: {
        show: true,
      },
      offlineTrainInfo: {}, // 线下实训信息
      verification: false,
      verificationAddress: {},
      verificationFailAddress: {},
      taskId: "",
      businessId: "",
      tabIndex: "anchor2",
      offsetTop: 0,
      treeData: [],
      tabList: [
        {
          name: "简介",
          id: "anchor1",
        },
        {
          name: "目录",
          id: "anchor2",
        },
      ],
      taskDetail: {},
      taskRuleInfo: null,
    };
  },
  created() {
    this.taskId = this.$route.query.id;
    this.taskDetailWithoutLogin();
    this.findTaskRule();
  },
  beforeDestroy() {},
  mounted() {},
  /* eslint-disable */
  computed: {},
  methods: {
    // 规则
    async findTaskRule() {
      await this.$api.coursePlay
        .findTaskRule(this.$route.query.id)
        .then((res) => {
          this.taskRuleInfo = res.data.supervisionRule || null;
        });
    },
    clickNode(data) {
      // type 1目录 2素材 3考试 4直播
      // if (data.type === 1) {
      //   return false;
      // }
      // 直播回放未生成不进行跳转
      if (data.isReplay === 1 && !data.replayUrl && data.contentStatus === 2) {
        return false;
      }
      // TODO 待添加直播
      if (
        this.$dateFormat.dateFormat() < this.taskDetail.startTime &&
        +this.taskDetail.studyType === 1
      ) {
        this.$message.warning("课程未开始");
        return false;
      } else if (
        this.$dateFormat.dateFormat() > this.taskDetail.endTime &&
        (+this.taskDetail.studyType === 1 || +this.taskDetail.studyType === 2)
      ) {
        this.$message.warning("已到期，无法学习");
        return false;
      }
      /**
       * isLocked：上锁
       * lockMode: 1 自由学习 2 闯关模式
       */
      if (data.isLocked) {
        this.$message.warning({
          message: "完成上一个学习内容才能开启当前学习内容哦～",
          offset: 70,
        });
        return false;
      }
      // 上锁
      // if (data.isLocked && data.type !== 1) {
      //   this.$message.warning({ message: this.multiPopTips(data.rule), offset: 70 });
      //   return false;
      // }
      if (data.type === 2) {
        // 素材
        /**
         * isCourseFaceRecognize 人脸识别 0 否 1 是
         */
        if (
          this.taskRuleInfo.isCourseFaceRecognize &&
          (!data.studyInfo || (data.studyInfo.learnRate || 0) < 100)
        ) {
          this.businessId = data.contentId;
          this.verification = true;
          this.confirmdialog.show = true;

          this.verificationAddress = {
            path: "/course/play",
            query: {
              resId: data.contentId,
              studyProcess: data.studyInfo?.learnRate || "",
              courseName: data.contentName,
              taskId: this.taskId,
              backPathName: this.$route.path,
              courseItemId: data.id,
            },
          };
        } else {
          console.log(data,'data---')
          this.$router.push({
            path: "/course/play",
            query: {
              resId: data.contentId,
              studyProcess: data.studyInfo?.learnRate || "",
              courseName: data.contentName,
              taskId: this.taskId,
              backPathName: this.$route.path,
              courseItemId: data.id,
              //source: this.$route.query.source || '',//2023-12-12-优化-素材/课程单独播放：添加source参数
              //parentId: data.parentId,//2023-12-12-优化-素材/课程单独播放：添加parentId参数
            },
          });
        }
      } else if (data.type === 3) {
        // 考试
        if (this.taskDetail.studyType === 3) {
          this.timeSlot = 0;
        }
        this.$router.push({
          path: "/examDetails",
          query: {
            examId: data.contentId, //考试ID
            bussinessId: this.taskId, //任务ID
            taskId: this.taskId,
            type: 2, //任务2
            title: this.taskDetail.name,
            pathName: this.$route.path,
            timeSlot: this.timeSlot,
            startTime: this.taskDetail.startTime,
            endTime: this.taskDetail.endTime,
          },
        });
      } else if (data.type === 6) {
        // 问卷
        this.$router.push({
          path: "/learn/questionnaire",
          query: {
            type: 2, // type：1训练营 2培训任务
            typeId: this.taskId, // typeId：训练营id或者培训任务id
            wjId: data.contentId, // wjId：问卷ID
          },
        });
      } else if (data.type === 4) {
        // 直播
        goLiveHome(this, data);
      } else if (data.type === 5) {
        // 实操作业
        this.$router.push({
          path: "/operationHomeWork",
          query: {
            taskId: this.taskId,
            id: data.contentId, // 作业id
          },
        });
      } else if (data.type === 7) {
        //练习
        this.$router.push({
          path: "/pattern",
          query: {
            businessId: this.taskId,
            practiceId: data.contentId, // 练习id
            businessName: data.contentName, //练习名称
          },
        });
      } else if (data.type === 8) {
        // 线下实训
        let offlineTrainInfo = {};
        offlineTrainInfo = {
          ...data.offlineTrainInfo,
          name: data.contentName,
          id: data.contentId,
        };
        this.offlineTrainInfo = offlineTrainInfo;
        this.dialogVisible.show = true;
      }
    },
    multiPopTips(stageRules) {
      /**
       * 开启了阶段内上锁，但是没设置条件
       */
      if (
        stageRules.isLockCourse &&
        !stageRules.lockCourseRate &&
        !stageRules.lockExamCondition &&
        !stageRules.lockFormCommit &&
        !stageRules.lockHomeworkCondition
      ) {
        this.$message.warning({
          message: "完成上一个学习内容才能开启当前学习内容哦～",
          offset: 70,
        });
        return false;
      }
      /**
       * 设置了上锁并设置了条件
       */
      if (
        stageRules.lockCourseRate &&
        stageRules.lockExamCondition &&
        stageRules.lockFormCommit &&
        stageRules.lockHomeworkCondition
      ) {
        return `该内容未开启，开启条件为前面的学习内容学习进度达到${stageRules.lockCourseRate}%，考试及格，问卷提交，作业通过`;
      } else if (
        stageRules.lockCourseRate &&
        !stageRules.lockExamCondition &&
        !stageRules.lockFormCommit &&
        !stageRules.lockHomeworkCondition
      ) {
        return `该内容未开启，开启条件为前面的学习内容学习进度达到${stageRules.lockCourseRate}%`;
      } else if (
        !stageRules.lockCourseRate &&
        stageRules.lockExamCondition &&
        !stageRules.lockFormCommit &&
        !stageRules.lockHomeworkCondition
      ) {
        return `该内容未开启，开启条件为考试及格`;
      } else if (
        !stageRules.lockCourseRate &&
        !stageRules.lockExamCondition &&
        !stageRules.lockFormCommit &&
        stageRules.lockHomeworkCondition
      ) {
        return `该内容未开启，开启条件为作业通过`;
      } else if (
        !stageRules.lockCourseRate &&
        !stageRules.lockExamCondition &&
        stageRules.lockFormCommit &&
        !stageRules.lockHomeworkCondition
      ) {
        return `该内容未开启，开启条件为问卷提交`;
      } else if (
        stageRules.lockCourseRate &&
        !stageRules.lockExamCondition &&
        stageRules.lockFormCommit &&
        stageRules.lockHomeworkCondition
      ) {
        return `该内容未开启，开启条件为前面的学习内容学习进度达到${stageRules.lockCourseRate}%，问卷提交，作业通过`;
      } else if (
        stageRules.lockCourseRate &&
        stageRules.lockExamCondition &&
        !stageRules.lockFormCommit &&
        stageRules.lockHomeworkCondition
      ) {
        return `该内容未开启，开启条件为前面的学习内容学习进度达到${stageRules.lockCourseRate}%，考试及格，作业通过`;
      } else if (
        stageRules.lockCourseRate &&
        stageRules.lockExamCondition &&
        stageRules.lockFormCommit &&
        !stageRules.lockHomeworkCondition
      ) {
        return `该内容未开启，开启条件为前面的学习内容学习进度达到${stageRules.lockCourseRate}%，考试及格，问卷提交`;
      } else if (
        stageRules.lockCourseRate &&
        stageRules.lockExamCondition &&
        !stageRules.lockFormCommit &&
        !stageRules.lockHomeworkCondition
      ) {
        return `该内容未开启，开启条件为前面的学习内容学习进度达到${stageRules.lockCourseRate}%，考试及格`;
      } else if (
        stageRules.lockCourseRate &&
        !stageRules.lockExamCondition &&
        stageRules.lockFormCommit &&
        !stageRules.lockHomeworkCondition
      ) {
        return `该内容未开启，开启条件为前面的学习内容学习进度达到${stageRules.lockCourseRate}%，问卷提交`;
      } else if (
        stageRules.lockCourseRate &&
        !stageRules.lockExamCondition &&
        stageRules.lockFormCommit &&
        !stageRules.lockHomeworkCondition
      ) {
        return `该内容未开启，开启条件为前面的学习内容学习进度达到${stageRules.lockCourseRate}%，作业通过`;
      } else if (
        !stageRules.lockCourseRate &&
        !stageRules.lockExamCondition &&
        stageRules.lockFormCommit &&
        stageRules.lockHomeworkCondition
      ) {
        return "该内容未开启，开启条件为问卷提交，作业提交";
      } else if (
        !stageRules.lockCourseRate &&
        stageRules.lockExamCondition &&
        stageRules.lockFormCommit &&
        !stageRules.lockHomeworkCondition
      ) {
        return "该内容未开启，开启条件为考试及格，问卷提交";
      } else if (
        !stageRules.lockCourseRate &&
        stageRules.lockExamCondition &&
        !stageRules.lockFormCommit &&
        stageRules.lockHomeworkCondition
      ) {
        return "该内容未开启，开启条件为考试及格，作业提交";
      } else if (
        !stageRules.lockCourseRate &&
        stageRules.lockExamCondition &&
        stageRules.lockFormCommit &&
        stageRules.lockHomeworkCondition
      ) {
        return "该内容未开启，开启条件为考试及格，问卷提交，作业提交";
      }
    },
    // 初始化详情
    async taskDetailWithoutLogin() {
      const params = {
        id: this.taskId || null,
      };
      await this.$api.courseCenter
        .taskDetailWithoutLogin({ params })
        .then(async (res) => {
          if (!res.data && res.message === "用户尚未添加到该培训任务") {
            this.$router.go(-1);
            return false;
          }
          if (res.data) {
            this.taskDetail = res.data;
            this.treeData = res.data.taskItemVoList || [];
            // 培训任务不显示阶段
            if(res.data.type==4){
              this.treeData = res.data.taskItemVoList[0].children || []
            }
            /**
             * studyType 学习周期类型 1区间 2购买后参数 3永久有效"
             * （长期有效默认打开的是目录/项目周期开始前是简介，开始后是目录）
             */
            if (this.taskDetail.studyType === 3) {
              this.tabIndex = "anchor2";
            }
            if (
              (this.taskDetail.studyType === 1 ||
                this.taskDetail.studyType === 2) &&
              new Date().getTime() <=
                new Date(this.taskDetail.startTime).getTime()
            ) {
              this.tabIndex = "anchor1";
            } else {
              this.tabIndex = "anchor2";
            }
            this.taskDetailExt();
          }
        });
    },
    /** 遍历大纲 返回学习相关信息 */
    changeTaskTree(data, data2) {
      const idToItem2Map = {};
      // 构建 data2 的哈希表，以便通过 id 查找项
      loop(data2);
      function loop(node) {
        node.forEach((item2) => {
          idToItem2Map[item2.id] = item2;
          if (item2.children && item2.children.length) {
            loop(item2.children);
          }
        });
      }

      console.log("idToItem2Map", idToItem2Map);
      /* eslint-disable */
      // 递归地更新 data2 中的项
      function updateData2(data) {
        data.forEach((item) => {
          if (idToItem2Map.hasOwnProperty(item.id)) {
            const item2 = idToItem2Map[item.id];
            item2.studyProcess = item.studyProcess;
            item2.studyInfo = item.studyInfo;
            item2.isLocked = item.isLocked;
            item2.submitStatus = item.submitStatus;
            item2.courseId = item.courseId;
            item2.contentStatus = item.contentStatus;
          }
          if (item.children && item.children.length) {
            updateData2(item.children);
          }
        });
      }
      // 开始递归更新
      updateData2(data);
      console.log("data2", data2);
      this.treeData = data2;
    },
    // 二次渲染任务详情 返回学习
    taskDetailExt() {
      const params = {
        id: this.taskId,
      };
      this.$api.learn.taskDetailExts({ params }).then((res) => {
        if (res.data) {
          this.taskDetail = {
            ...this.taskDetail,
            learnTotalTime: res.data.learnTotalTime,
            courseNum: res.data.courseNum || 1,
            studyProcess: res.data.studyProcess,
            endTime: res.data.endTime,

            lockCourseRate: res.data.lockCourseRate,
            lockExamCondition: res.data.lockExamCondition,
            isFormCommit: res.data.isFormCommit,
          };
          let tree = [];
          for (let key in res.data.itemMap) {
            tree.push(res.data.itemMap[key]);
          }
          console.log("tree", tree);

          this.changeTaskTree(tree, this.treeData);
          console.log("this.treeData", this.treeData);
        }
      });
    },
    jump(id) {
      this.tabIndex = id;
      // const box = document.getElementById('scroll-box')
      // let scrollItem = document.getElementById(id)
      // // 锚点对应的模块与最近的一个具有定位的祖宗元素顶部的距离
      // console.log(scrollItem, 'scrollItem.offsetTop')
      // this.offsetTop = scrollItem?.offsetTop;
      // console.log(box, 'box====', this.offsetTop)
      // box.scrollTo({
      //   top: scrollItem.offsetTop,
      //   behavior: "smooth",
      // });
    },
    scroll() {
      const box = document.getElementById("scroll-box");
      console.log(box.scrollTop, "box");
      console.log(this.offsetTop, "offsetTop==");
      // 若当前设置的滚动高度大于实际滚动的高度，即为锚点跳转，不再设置选中的锚点
      if (this.offsetTop > box.scrollTop) {
        this.offsetTop = 0;
        return;
      }
      let totalH = 0;
      this.tabList.some((anchor) => {
        let scrollItem = document.getElementById(anchor.id); // 锚点对应的模块

        totalH = totalH + scrollItem.clientHeight;
        console.log(box.scrollTop, "box.scrollTop");
        console.log(totalH, "totalH===");
        let judge = box.scrollTop < totalH;
        console.log(judge, "judge====");
        if (judge) {
          this.tabIndex = anchor.id;
          return true;
        }
      });
    },
  },
};
</script>
<style
  lang="scss"
  src="../../courseCenter/assets/css/detail.scss"
  scoped
></style>
<style lang="scss" src="../asset/css/courseDetail.scss" scoped></style>
